// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-all-pages-jsx": () => import("./../../../src/pages/all-pages.jsx" /* webpackChunkName: "component---src-pages-all-pages-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-posts-jsx": () => import("./../../../src/pages/posts.jsx" /* webpackChunkName: "component---src-pages-posts-jsx" */),
  "component---src-pages-works-jsx": () => import("./../../../src/pages/works.jsx" /* webpackChunkName: "component---src-pages-works-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-work-jsx": () => import("./../../../src/templates/work.jsx" /* webpackChunkName: "component---src-templates-work-jsx" */)
}

